const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

export const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const random = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const getColumnByProjectTitle = (projectTitle, tasks) => {
  for (const columnId in tasks.columns) {
    if (tasks.columns[columnId].title === projectTitle) {
      return tasks.columns[columnId];
    }
  }
  return null;
};

export const getTaskByTaskId = (taskId, tasks) => {
  for (const taskIdKey in tasks.tasks) {
    if (taskIdKey === taskId) {
      return tasks.tasks[taskIdKey];
    }
  }
  return null;
};

export const getCompletedTime = () => {
  const date = new Date();
  const pad = (num) => String(num).padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? '+' : '-';
  const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);
  const formattedString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`
  return formattedString
};

// export const getSubTaskBySubTaskId = (subTaskId, subTasks) => {
//   for (const subTaskIdKey in subTasks) {
//     if (subTaskIdKey === subTaskId) {
//       return subTasks[subTaskIdKey];
//     }
//   }
//   return null;
// };

export const updateOrder = async (project, taskSequence, token) => {
  const URL = AUTH_ENDPOINT + `task-sequence?project=${project}`;

  const HEADERS = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  const BODY = JSON.stringify(taskSequence);
  const request = new Request(URL, {
    method: 'PUT',
    headers: HEADERS,
    body: BODY,
    timeout: 100000,
  });
  fetch(request);
};
