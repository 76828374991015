import React from 'react';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import styled from 'styled-components';
import {
  getTaskByTaskId,
  getColumnByProjectTitle,
  updateOrder,
  getCompletedTime,
} from '../../utils';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

function SubTask({ subTaskId, taskId }) {
  const { subTasks, setSubTasks, setTasks, tasks, deleteSubTask } =
    React.useContext(DataContext);
  const { token } = React.useContext(UserContext);

  const subTask = subTasks[subTaskId];
  const isComplete = subTask.status === 'COMPLETE';

  const handleDelete = (item) => {
    setTasks((oldTasks) => {
      // const column = getColumnByProjectTitle(item.project, oldTasks);

      const task = getTaskByTaskId(taskId, oldTasks);

      const newSubTasks = task.subtasks.filter(
        (subtask) => subtask !== item.id
      );

      const newTask = {
        ...task,
        subtasks: newSubTasks,
      };

      const updatedTasks = {
        ...oldTasks.tasks,
        [taskId]: {
          ...newTask,
        },
      };

      const newData = {
        ...oldTasks,
        tasks: updatedTasks,
        columns: {
          ...tasks.columns,
        },
      };

      return newData;
    });
    deleteSubTask(item.id, taskId);
  };

  const updateSubTask = async (item, taskId, token) => {
    const itemData = { ...item };

    if (item.status === 'COMPLETE') {
      itemData['completed_time'] = getCompletedTime();
    }

    const URL = AUTH_ENDPOINT + `subtask`;
    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const BODY = JSON.stringify(itemData);
    const request = new Request(URL, {
      method: 'PUT',
      headers: HEADERS,
      body: BODY,
      timeout: 100000,
    });
    fetch(request);
  };

  const handleComplete = (item, taskId) => {
    console.log('handling complete!');

    setSubTasks((oldTasks) => {
      const currentStatus = oldTasks[item.id].status;
      var newStatus = currentStatus === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE';

      const newItem = oldTasks[item.id];
      newItem.status = newStatus;
      const updatedTasks = { ...oldTasks, [item.id]: newItem };

      updateSubTask(newItem, taskId, token);

      // Grab task (or pass in as arg, maybe the latter)
      // Iterate through subtasks
      // Find index of first subtask
      // set new subtask order
      // const taskIds = oldTasks.columns[columnId].taskIds;
      // if (newStatus === 'COMPLETE') {
      //   const firstCompleteIndex = taskIds.findIndex(
      //     (taskId) =>
      //       taskId !== item.id && updatedTasks[taskId].status === 'COMPLETE'
      //   );

      //   const currentIndex = taskIds.indexOf(item.id);
      //   if (firstCompleteIndex === -1) {
      //     taskIds.splice(currentIndex, 1);
      //     taskIds.push(item.id);
      //   } else if (currentIndex < firstCompleteIndex) {
      //     taskIds.splice(currentIndex, 1);
      //     taskIds.splice(firstCompleteIndex - 1, 0, item.id);
      //   }
      //   updateOrder(item.project, taskIds, token);
      // }

      return updatedTasks;
    });

    //updateSubTask(newItem, token);

    // const column = getColumnByProjectTitle(item.project, oldTasks);
    // const columnId = column['id'];
    // const taskIds = oldTasks.columns[columnId].taskIds;
    // if (newStatus === 'COMPLETE') {
    //   const firstCompleteIndex = taskIds.findIndex(
    //     (taskId) =>
    //       taskId !== item.id && updatedTasks[taskId].status === 'COMPLETE'
    //   );

    //   const currentIndex = taskIds.indexOf(item.id);
    //   if (firstCompleteIndex === -1) {
    //     taskIds.splice(currentIndex, 1);
    //     taskIds.push(item.id);
    //   } else if (currentIndex < firstCompleteIndex) {
    //     taskIds.splice(currentIndex, 1);
    //     taskIds.splice(firstCompleteIndex - 1, 0, item.id);
    //   }
    //   updateOrder(item.project, taskIds, token);
    // }

    // return {
    //   tasks: updatedTasks,
    //   columns: {
    //     ...oldTasks.columns,
    //     [columnId]: {
    //       ...oldTasks.columns[columnId],
    //       taskIds: taskIds,
    //     },
    //   },
    //   columnOrder: oldTasks.columnOrder,
    // };
  };

  return (
    <Container>
      {true && (
        <span
          style={{
            width: '20px',
            height: '20px',
            display: 'inline-block',
            marginRight: '5px',
            cursor: 'pointer',
          }}
          onClick={() => handleComplete(subTask, taskId)}
        >
          {isComplete ? dollarIcon : boxIcon}
        </span>
      )}
      <span
        style={{
          width: '20px',
          height: '20px',
          display: 'inline-block',
          marginRight: '5px',
          cursor: 'pointer',
        }}
        onClick={() => handleComplete(subTask)}
        // onClick={() => handleMoveToTop(subTask)}
      >
        {upArrow}
      </span>

      <span
        style={{
          width: '20px',
          height: '20px',
          display: 'inline-block',
          marginRight: '5px',
          cursor: 'pointer',
        }}
        onClick={() => handleDelete(subTask)}
      >
        {trashcanIcon}
      </span>
      {subTask.content}
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid lightgrey;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  background-color: #f6f8f6;
  color: #464646;
  /* padding: 5px; */
`;

const trashcanIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-trash-2'
  >
    <path d='M3 6h18' />
    <path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
    <path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
    <line x1='10' x2='10' y1='11' y2='17' />
    <line x1='14' x2='14' y1='11' y2='17' />
  </svg>
);

const dollarIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-check'
  >
    <path d='M20 6 9 17l-5-5' />
  </svg>
);

const boxIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-box-select'
  >
    <path d='M5 3a2 2 0 0 0-2 2' />
    <path d='M19 3a2 2 0 0 1 2 2' />
    <path d='M21 19a2 2 0 0 1-2 2' />
    <path d='M5 21a2 2 0 0 1-2-2' />
    <path d='M9 3h1' />
    <path d='M9 21h1' />
    <path d='M14 3h1' />
    <path d='M14 21h1' />
    <path d='M3 9v1' />
    <path d='M21 9v1' />
    <path d='M3 14v1' />
    <path d='M21 14v1' />
  </svg>
);

const upArrow = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-square-arrow-up'
  >
    <rect width='18' height='18' x='3' y='3' rx='2' />
    <path d='m16 12-4-4-4 4' />
    <path d='M12 16V8' />
  </svg>
);

export default SubTask;
