import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './styles2sbtsk.css';

import { ulid } from 'ulid';

import { UserContext } from '../UserProvider';
import { DataContext } from '../DataProvider';

import { setHours, setMinutes, formatISO } from 'date-fns';

import { getColumnByProjectTitle, getTaskByTaskId } from '../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

const SubTaskDialog = ({ project, taskId }) => {
  // const AuthDialog = () => {
  const [subTask, setSubTask] = React.useState('');
  const [showDatepicker, setShowDatepicker] = React.useState(false);
  const [intervalType, setIntervalType] = React.useState('REGULAR');
  const { tasks, setTasks, setSubTasks } = React.useContext(DataContext);
  const [startDate, setStartDate] = React.useState(
    setHours(setMinutes(new Date(), 59), 23)
  );
  const [estimatedTime, setEstimatedTime] = React.useState('');

  const handleClose = () => {
    setSubTask('');
    setShowDatepicker(false);
    setStartDate(setHours(setMinutes(new Date(), 59), 23));
    setEstimatedTime('');
  };

  const { token } = React.useContext(UserContext);
  const createSubTask = async () => {
    const URL = AUTH_ENDPOINT + `subtask`;
    const column = getColumnByProjectTitle(project, tasks);
    const task = getTaskByTaskId(taskId, tasks);

    const subTaskUlid = ulid();

    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };

    const formattedStartDate = formatISO(startDate);

    const BODY = JSON.stringify({
      content: subTask,
      taskId: taskId,
      // project: project,
      id: subTaskUlid,
      ...(showDatepicker && { deadline: startDate }),
      ...(estimatedTime && { estimatedTime: parseInt(estimatedTime, 10) }),
    });
    const request = new Request(URL, {
      method: 'POST',
      headers: HEADERS,
      body: BODY,
      timeout: 100000,
    });
    const result = fetch(request);
    setSubTasks((oldSubTasks) => {
      const newSubTask = {
        id: subTaskUlid,
        content: subTask,
        task,
        status: 'INCOMPLETE',
      };
      const newData = {
        ...oldSubTasks,
        [subTaskUlid]: newSubTask,
      };

      return newData;
    });
    setTasks((oldTasks) => {
      const newSubTasks = [...task.subtasks, subTaskUlid];
      const newTask = {
        id: taskId,
        content: task.content,
        project,
        status: 'INCOMPLETE',
        subtasks: newSubTasks,
      };
      const allTasks = {
        ...oldTasks.tasks,
        [taskId]: newTask,
      };
      const oldColumn = getColumnByProjectTitle(project, oldTasks);
      const oldColumnId = column['id'];
      const newData = {
        ...oldTasks,
        tasks: allTasks,

        ...tasks.columns,
      };

      return newData;
    });
    setSubTask('');
  };

  function handleAddAttempt() {
    createSubTask();
  }

  return (
    <Dialog.Root onOpenChange={handleClose}>
      <Dialog.Trigger asChild>
        <span className='SubTaskButton violet'>{plusIcon}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='SubTaskDialogOverlay' />
        <Dialog.Content className='SubTaskDialogContent'>
          <Dialog.Title className='SubTaskDialogTitle'>
            Add SubTask
          </Dialog.Title>
          <fieldset className='SubTaskFieldset'>
            <label className='SubTaskLabel' htmlFor='task'>
              SubTask
            </label>
            <input
              value={subTask}
              onChange={(e) => setSubTask(e.target.value)}
              className='SubTaskInput'
              id='subtask'
            />
          </fieldset>
          {!showDatepicker && (
            <fieldset className='SubTaskFieldset'>
              <label className='SubTaskLabel' htmlFor='addDeadline'>
                + Deadline
              </label>
              <input
                type='checkbox'
                id='addDeadline'
                className='CheckboxInput'
                checked={showDatepicker}
                onChange={() => setShowDatepicker(!showDatepicker)}
              />
            </fieldset>
          )}
          {showDatepicker && (
            <fieldset className='SubTaskFieldset'>
              <label className='SubTaskLabel' htmlFor='deadline'>
                Deadline
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeIntervals={5}
                dateFormat='MMMM d, yyyy h:mm aa'
              >
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'red',
                  }}
                ></div>
              </DatePicker>
              <button
                onClick={() => setShowDatepicker(false)}
                className='CloseButton'
              >
                {closeIcon}
              </button>
            </fieldset>
          )}
          <fieldset className='SubTaskFieldset'>
            <label className='SubTaskLabel' htmlFor='estimatedTime'>
              Est. Time
            </label>
            <input
              type='text'
              value={estimatedTime}
              onChange={(e) => setEstimatedTime(e.target.value)}
              className='SubTaskInput'
              id='estimatedTime'
              style={{
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-m"><text x="0" y="20" font-family="Arial" font-size="20">m</text></svg>')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                paddingRight: '30px',
              }}
            />
          </fieldset>
          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'flex-end',
            }}
          >
            <Dialog.Close asChild>
              <button
                onClick={handleAddAttempt}
                className='SubTaskButton green'
              >
                Submit
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='SubTaskIconButton' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SubTaskDialog;

const closeIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-x'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='m15 9-6 6' />
    <path d='m9 9 6 6' />
  </svg>
);

const plusIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    stroke='currentColor'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{ transform: 'translateY(3px)', marginRight: '5px' }}
    className='lucide lucide-circle-plus'
  >
    <circle cx='10' cy='10' r='8.34' />
    <path d='M6.66 10h6.675' />
    <path d='M10 6.66v6.675' />
  </svg>
);
