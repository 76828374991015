import React from 'react';

import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import {
  getColumnByProjectTitle,
  updateOrder,
  getCompletedTime,
} from '../../utils';
import SubTaskDialog from '../SubTaskDialog';
import SubTask from '../SubTask';

import Countdown from '../Countdown';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

function Task({ task, index }) {
  const { setTasks, deleteTask, tasks } = React.useContext(DataContext);
  const [habitRating, setHabitRating] = React.useState(
    parseInt(task.habit_rating) + 1
  );
  const { token } = React.useContext(UserContext);

  const isComplete = task.status === 'COMPLETE';
  const textColor = isComplete ? '#a0a0a0' : '#f0f0f0';
  const textDecoration = isComplete ? 'line-through' : 'none';
  const hasDeadline = task.deadline !== undefined && task.deadline !== null;
  const isHabit =
    task.project !== undefined &&
    task.project !== null &&
    task.project === 'habits';

  const provideHabitRating = (rating, item) => {
    setHabitRating(rating);
    handleComplete(item, rating);
  };

  const updateTask = async (task, token) => {
    const taskData = { ...task };
    if (task.project === 'habits') {
      taskData['habit_rating'] = Math.max(task.habit_rating - 1, 0).toString();
    }
    if (task.status === 'COMPLETE') {
      taskData['completed_time'] = getCompletedTime();
    }

    const URL = AUTH_ENDPOINT + `task`;
    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const BODY = JSON.stringify(taskData);
    const request = new Request(URL, {
      method: 'PUT',
      headers: HEADERS,
      body: BODY,
      timeout: 100000,
    });
    fetch(request);
  };

  const handleDelete = (item) => {
    setTasks((oldTasks) => {
      const column = getColumnByProjectTitle(item.project, oldTasks);
      const columnId = column['id'];
      delete tasks[item.id];
      const updatedTaskIds = oldTasks.columns[columnId].taskIds.filter(
        (taskId) => taskId !== item.id
      );
      const newColumn = {
        ...column,
        taskIds: updatedTaskIds,
      };
      const newData = {
        ...tasks,
        columns: {
          ...tasks.columns,
          [newColumn.id]: newColumn,
        },
      };

      return newData;
    });
    deleteTask(item.id);
  };

  const handleComplete = (item, rating = null) => {
    setTasks((oldTasks) => {
      const updatedTasks = { ...oldTasks.tasks };
      const currentStatus = updatedTasks[item.id].status;
      var newStatus = currentStatus === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE';
      if (rating) {
        newStatus = 'COMPLETE';
      }

      updatedTasks[item.id].status = newStatus;
      if (item.project === 'habits') {
        updatedTasks[item.id].habit_rating = rating;
      }
      const newItem = updatedTasks[item.id];
      updateTask(newItem, token);

      const column = getColumnByProjectTitle(item.project, oldTasks);
      const columnId = column['id'];
      const taskIds = oldTasks.columns[columnId].taskIds;
      if (newStatus === 'COMPLETE') {
        const firstCompleteIndex = taskIds.findIndex(
          (taskId) =>
            taskId !== item.id && updatedTasks[taskId].status === 'COMPLETE'
        );

        const currentIndex = taskIds.indexOf(item.id);
        if (firstCompleteIndex === -1) {
          taskIds.splice(currentIndex, 1);
          taskIds.push(item.id);
        } else if (currentIndex < firstCompleteIndex) {
          taskIds.splice(currentIndex, 1);
          taskIds.splice(firstCompleteIndex - 1, 0, item.id);
        }
        updateOrder(item.project, taskIds, token);
      }

      return {
        tasks: updatedTasks,
        columns: {
          ...oldTasks.columns,
          [columnId]: {
            ...oldTasks.columns[columnId],
            taskIds: taskIds,
          },
        },
        columnOrder: oldTasks.columnOrder,
      };
    });
  };

  const handleMoveToTop = (item) => {
    setTasks((oldTasks) => {
      const updatedTasks = { ...oldTasks.tasks };
      const column = getColumnByProjectTitle(item.project, oldTasks);
      const columnId = column['id'];
      const taskIds = oldTasks.columns[columnId].taskIds;
      const currentIndex = taskIds.indexOf(item.id);

      if (currentIndex > 0) {
        taskIds.splice(currentIndex, 1);
        taskIds.unshift(item.id);
        updateOrder(item.project, taskIds, token);
      }

      return {
        tasks: updatedTasks,
        columns: {
          ...oldTasks.columns,
          [columnId]: {
            ...oldTasks.columns[columnId],
            taskIds: taskIds,
          },
        },
        columnOrder: oldTasks.columnOrder,
      };
    });
  };

  const renderContentWithLinks = (content) => {
    if (!content) return null;
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|\bwww\.\S+\.\S+)/gi;
    return content
      .split(urlRegex)
      .filter(Boolean)
      .map((part, index) => {
        if (urlRegex.test(part)) {
          const url = part.startsWith('http') ? part : `http://${part}`;
          const displayText =
            part.length > 45 ? `${part.slice(0, 42)}...` : part;
          const finalDisplayText = displayText.replace(
            /^(https?:\/\/)?(www\.)?/,
            ''
          );
          return (
            <StyledLink
              key={index}
              href={url}
              target='_blank'
              rel='noopener noreferrer'
            >
              {finalDisplayText}
            </StyledLink>
          );
        }
        return part === 'https' ? '' : part;
      });
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => {
        return (
          <Container {...provided.draggableProps} ref={provided.innerRef}>
            <span
              {...provided.dragHandleProps}
              style={{
                width: '20px',
                height: '20px',
                display: 'inline-block',
                marginRight: '5px',
              }}
            >
              {moveableIcon}
            </span>
            {!isHabit && (
              <span
                style={{
                  width: '20px',
                  height: '20px',
                  display: 'inline-block',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => handleComplete(task)}
              >
                {isComplete ? dollarIcon : boxIcon}
              </span>
            )}
            <span
              style={{
                width: '20px',
                height: '20px',
                display: 'inline-block',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              onClick={() => handleMoveToTop(task)}
            >
              {upArrow}
            </span>

            <span
              style={{
                width: '20px',
                height: '20px',
                display: 'inline-block',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              onClick={() => handleDelete(task)}
            >
              {trashcanIcon}
            </span>
            <button>
              <SubTaskDialog project={task.project} taskId={task.id} />
            </button>
            <span
              style={{
                color: textColor,
                textDecoration: textDecoration,
                fontWeight: '400',
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              {renderContentWithLinks(task.content)}
            </span>
            {isHabit && (
              <HabitRatingContainer>
                {[1, 2, 3, 4, 5].map((num) => (
                  <HabitRatingButton
                    key={num}
                    onClick={() => provideHabitRating(num, task)}
                    active={habitRating >= num}
                  >
                    {num}
                  </HabitRatingButton>
                ))}
              </HabitRatingContainer>
            )}
            <span>
              {hasDeadline && !isHabit && !isComplete ? (
                <Countdown deadline={task.deadline} />
              ) : (
                ''
              )}
            </span>
            {task.subtasks.length > 0 && (
              <div style={{ paddingTop: '10px' }}>
                {task.subtasks.map((subTaskId) => {
                  return (
                    <SubTask
                      key={subTaskId}
                      taskId={task.id}
                      subTaskId={subTaskId}
                    />
                  );
                })}
              </div>
            )}
          </Container>
        );
      }}
    </Draggable>
  );
}

const StyledLink = styled.a`
  color: #ffea28;
  &:visited {
    color: #ffea28;
  }
`;

const Container = styled.div`
  border: 1px solid lightgrey;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  background-color: #5061ff;
  /* padding: 5px; */
`;

const trashcanIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-trash-2'
  >
    <path d='M3 6h18' />
    <path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
    <path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
    <line x1='10' x2='10' y1='11' y2='17' />
    <line x1='14' x2='14' y1='11' y2='17' />
  </svg>
);

const moveableIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    flexgrow='2'
    className='lucide lucide-align-justify'
  >
    <line x1='3' x2='21' y1='6' y2='6' />
    <line x1='3' x2='21' y1='12' y2='12' />
    <line x1='3' x2='21' y1='18' y2='18' />
  </svg>
);

// const dollarIcon = (
//   <svg
//     xmlns='http://www.w3.org/2000/svg'
//     width='24'
//     height='24'
//     viewBox='0 0 24 24'
//     fill='none'
//     // stroke='currentColor'
//     stroke='hsl(120, 50%, 25%)'
//     strokeWidth='2'
//     strokeLinecap='round'
//     strokeLinejoin='round'
//     className='lucide lucide-badge-dollar-sign'
//   >
//     <path d='M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z' />
//     <path d='M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8' />
//     <path d='M12 18V6' />
//   </svg>
// );

const dollarIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-check'
  >
    <path d='M20 6 9 17l-5-5' />
  </svg>
);

const boxIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-box-select'
  >
    <path d='M5 3a2 2 0 0 0-2 2' />
    <path d='M19 3a2 2 0 0 1 2 2' />
    <path d='M21 19a2 2 0 0 1-2 2' />
    <path d='M5 21a2 2 0 0 1-2-2' />
    <path d='M9 3h1' />
    <path d='M9 21h1' />
    <path d='M14 3h1' />
    <path d='M14 21h1' />
    <path d='M3 9v1' />
    <path d='M21 9v1' />
    <path d='M3 14v1' />
    <path d='M21 14v1' />
  </svg>
);

const upArrow = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-square-arrow-up'
  >
    <rect width='18' height='18' x='3' y='3' rx='2' />
    <path d='m16 12-4-4-4 4' />
    <path d='M12 16V8' />
  </svg>
);

export default Task;

const HabitRatingButton = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 0.5rem;
  background-color: ${(props) => (props.active ? '#007EE5' : 'F8F9FA')};
  color: ${(props) => (props.active ? 'white' : 'black')};

  /* Centering the text */
  display: inline-block; /* Makes it an inline element with block-like properties */
  text-align: center; /* Centers the text horizontally */
  line-height: 2rem; /* Vertically centers the text */
  cursor: pointer;
`;

const HabitRatingContainer = styled.div`
  margin-left: 55px;
  margin-top: 7px;
`;

// const HabitRatingButton = styled.button`
//   width: 2rem;
//   height: 2rem;
//   border-radius: 50%;
//   margin-right: 0.5rem;
//   background-color: ${(props) => (props.active ? 'blue' : 'gray')};
//   color: ${(props) => (props.active ? 'white' : 'black')};
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
